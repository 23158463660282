import { Container } from 'react-bootstrap';
import Footer from './components/Footer';
import Header from "./components/Header";
import HomeScreen from './screens/HomeScreen';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import ProductScreen from './screens/ProductScreen';
import CartScreen from './screens/CartScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import ProfileScreen from './screens/ProfileScreen';
import PasswordScreen from './screens/PasswordScreen';
import ShippingScreen from './screens/ShippingScreen';
import PaymentScreen from './screens/PaymentScreen';
import PlaceorderScreen from './screens/PlaceorderScreen';
import OrderScreen from './screens/OrderScreen';
import StockScreen from './screens/StockScreen';


import InfoScreen from './screens/InfoScreen';


const GetTongji = props => {
  let children = props.children;
  (function() {
    var hm = document.createElement("script");
    hm.charset = "UTF-8";
    hm.id = "LA_COLLECT";
    hm.src = "https://sdk.51.la/js-sdk-pro.min.js?id=KSg6RvYMSdRx7dJm&ck=KSg6RvYMSdRx7dJm";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
  })();
  return children;
};

function App() {
  return (
    <Router>
        <Header></Header>
        <main className="py-3">
          <Container>
            <GetTongji>
              <Route path='/infos/:id/:typeIdNum/:pageNumber/:titleContent' component={InfoScreen} />
              <Route path='/cart/:productKey?' component={CartScreen} />
              <Route path='/products/:productKey' component={ProductScreen} />
              <Route path='/' component={HomeScreen} exact />
              <Route path='/home/:typeIdNum/:pageNumber/:titleContent' component={HomeScreen} exact />
              <Route path='/login' component={LoginScreen} />
              <Route path='/register' component={RegisterScreen} />
              <Route path='/profile' component={ProfileScreen} />
              <Route path='/updatePassword' component={PasswordScreen} />
              <Route path='/shipping' component={ShippingScreen} />
              <Route path='/payment' component={PaymentScreen} />
              <Route path='/placeorder' component={PlaceorderScreen} />
              <Route path='/order/:orderKey' component={OrderScreen} />
              <Route path='/stockList' component={StockScreen} />
            </GetTongji>
          </Container>
        </main>
        <Footer/>
    </Router>
  );
} 

export default App; 
