import {
    TYPE_LIST_REQUEST,
    TYPE_LIST_SUCCESS,
    TYPE_LIST_FAIL,
  } from '../contents/TypeConstents.js'
  import { logout } from './UserActions.js'
  import TypeService from '../service/TypeService.js'
  
  // 获取类型列表
  export const typeInfos = () => async (
    dispatch
  ) => {
    try {
      dispatch({ type: TYPE_LIST_REQUEST })
      TypeService.getTypeList()
        .then(res => {
            //console.log("listProducts="+JSON.stringify(res));
            if(res == null) {
                console.log("服务端异常！");
                dispatch({ type: TYPE_LIST_FAIL, payload: "服务端异常" })
                return ;
            } else if(res.retCode !== 200) {
                dispatch({ type: TYPE_LIST_FAIL, payload: res.message })
                // 重新登录
                if(res.retCode === 901000) {
                  dispatch(logout());
                }
                return ;
            }
            dispatch({ type: TYPE_LIST_SUCCESS, payload: res.retValue })
        });
    } catch (error) {
      dispatch({
        type: TYPE_LIST_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      })
    }
  }
  
