import {
    TYPE_LIST_REQUEST,
    TYPE_LIST_SUCCESS,
    TYPE_LIST_FAIL,
  } from '../contents/TypeConstents'
  
  //获取类型reducer
  export const typeListReducer = (state = { types: [] }, action) => {
    switch (action.type) {
      case TYPE_LIST_REQUEST:
        return { typeLoading: true, types: [] }
      case TYPE_LIST_SUCCESS:
          //console.log("typeListReducer="+ JSON.stringify(action));
        return {
          typeLoading: false,
          types: action.payload,
        }
      case TYPE_LIST_FAIL:
        return { typeLoading: false, typeError: action.payload }
      default:
        return state
    }
  }
  

  
 
  