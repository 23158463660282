import React, { useState, useEffect } from 'react'
//import products from '../products';
import { Row, Col, Alert, Pagination, Nav, Form, Button} from 'react-bootstrap'
import InputGroup from 'react-bootstrap/InputGroup';
import Info from '../components/Info'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { useDispatch, useSelector } from 'react-redux'
import { listInfos } from '../actions/InfoActions'
import { Formik }  from 'formik'
import { typeInfos } from '../actions/TypeActions'
import banner from '../static/image/banner.jpeg'
import FormContainer from '../components/FormContainer'


const HomeScreen = ({ match, history }) => {
    const [pageNumber, setPageNumber] = useState(match.params.pageNumber || 1)
    const [typeIdNum, setTypeIdNum] = useState(match.params.typeIdNum || 0)
    const [titleContent, setTitleContent] = useState(match.params.titleContent || 'null')

    const dispatch = useDispatch();
    const infoList = useSelector((state) => state.infoList);
    const { loading, error, infos, pageInfo } = infoList;

    const typeList = useSelector((state) => state.typeList);
    const { typeLoading, typeError, types } = typeList;

    const [pageList, setPageList] = useState([])
    const [pageNum, setPageNum] = useState(1)
    const [pageLast, setPageLast] = useState(1)
    const [typeId, setTypeId] = useState(1)
    const [title, setTitle] = useState('null');

    const handleSelect = (eventKey) => alert(`selected ${eventKey}`);

    useEffect(() => {
      console.log("HomeScreen pageNumber="+pageNumber + ",typeIdNum="+typeIdNum + ",titleContent="+titleContent);
        let pageParam = { 
            pageNum: pageNumber,
            pageSize: 16
        }
        let infoParam = {
            typeId: typeIdNum,
            title: titleContent,
            pageParam: pageParam
        }
        setTypeId(typeIdNum);
        setTitle(titleContent);
        dispatch(listInfos(infoParam))
      }, [dispatch, typeIdNum, pageNumber, titleContent , history])


    useEffect(() => {
      dispatch(typeInfos())
    }, [dispatch, history])

  /**
   * 分页参数
   */
  useEffect(() => {
    if(pageInfo !== null && pageInfo !== undefined) {
      setPageNum(pageInfo.pageNum);
      setPageLast(pageInfo.pages)
      if(pageInfo.pageNum < 5 && pageInfo.pages > 5) {
        //console.log("pageInfo.pageNum <= 5 && pageInfo.lastPage > 5")
        let list = [1,2,3,4,5,pageInfo.pages];
        setPageList(list);
      } else if(pageInfo.pages <= 5){
        //console.log("pageInfo.lastPage <= 5")
        let list = [];
        for(let i=1; i<= pageInfo.pages; i++ ) {
          list[i-1] = i;
        }
        setPageList(list);
      } else if(pageInfo.pageNum >= 5 && pageInfo.pageNum <= pageInfo.pages -2) {
        //console.log("pageInfo.pageNum > 5 && pageInfo.pageNum  <= pageInfo.lastPage -2")
        let list = [];
        list[0] = pageInfo.pageNum - 2;
        list[1] = pageInfo.pageNum - 1;
        list[2] = pageInfo.pageNum;
        list[3] = pageInfo.pageNum + 1;
        list[4] = pageInfo.pageNum + 2;
        if(pageInfo.pageNum + 2 < pageInfo.pages) {
          list[5] = pageInfo.pages;
        }
        setPageList(list);
      } else if(pageInfo.pageNum > 5 && pageInfo.pageNum === pageInfo.pages-1) {
        //console.log("pageInfo.pageNum > 5 && pageInfo.pageNum  ===  pageInfo.lastPage -1")
        let list = [];
        list[0] = pageInfo.pageNum - 3;
        list[1] = pageInfo.pageNum - 2;
        list[2] = pageInfo.pageNum - 1;
        list[3] = pageInfo.pageNum;
        list[4] = pageInfo.pageNum + 1;
        setPageList(list);
      } else if(pageInfo.pageNum > 5 && pageInfo.pageNum === pageInfo.pages) {
        //console.log("pageInfo.pageNum > 5 && pageInfo.pageNum  ===  pageInfo.lastPage")
        let list = [];
        list[0] = pageInfo.pageNum - 4;
        list[1] = pageInfo.pageNum - 3;
        list[2] = pageInfo.pageNum - 2;
        list[3] = pageInfo.pageNum - 1;
        list[4] = pageInfo.pageNum;
        setPageList(list);
      }
    }
  }, [pageInfo])


  /**
   * 分页查询
   * @param {*} pageNum 
   */
    const handlePage= (pageNum) => {
        //console.log("typeId=" + typeId + ",typeSecondId=" + typeSecondId + ",pageNum="+pageNum);
        if(pageNum>= pageLast) {
          pageNum = pageLast
        } else if (pageNum <= 1) {
          pageNum = 1
        }
        let pageParam = { 
            pageNum: pageNum,
            pageSize: 16
        }
        let infoParam = {
            typeId: typeId,
            title: title,
            pageParam: pageParam
        }
        setPageNum(pageNum)
        dispatch(listInfos(infoParam))
    }
    
    /**
     * 根据类型查询
     * @param {*} typeId 
     */
    const handleOneClick = (typeId) => {
      let pageParam = { 
          pageNum: 1,
          pageSize: 16
      }
      let infoParam = {
          typeId: typeId,
          pageParam: pageParam
      }

      setTypeId(typeId);
      setTitle('null');
      setPageNum(1)
      dispatch(listInfos(infoParam))
  };


  /**
   * 根据搜索查询
   * @param {*} pageNum 
   */
  const handleSearch= (title) => {
    let pageParam = { 
      pageNum: 1,
      pageSize: 16
    }
    let infoParam = {
        title: title,
        pageParam: pageParam
    }

    setTypeId(0);
    setTitle(title);
    setPageNum(1);
    dispatch(listInfos(infoParam))
  }


      /**
   * 表单提交函数
   * @param {*} values 
   */
  const submitHandler = (values) => {
    dispatch(handleSearch(values.title))
  }

    return (
        <>
            {/* <Row>
                <img src={banner} source={banner} alt="banner" style={{ width: "100%", height: 300, marginBottom: 20 }} onClick={() => imageClick()} />
            </Row> */}

            {
              <FormContainer>
                  <Formik
                  onSubmit={(values) => {
                    submitHandler(values);
                  }}
                  initialValues={{
                    title: '',
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                    setFieldValue,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <Row>
                        <Col>
                          <Form.Group controlId='title'>
                            <Form.Control
                              type='title'
                              placeholder='请输入搜索内容'
                              value={values.title}
                              onChange={handleChange}
                            ></Form.Control>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Button  Button type='submit' variant='primary'>
                                查询
                          </Button>
                        </Col>
                      </Row>
                     

                    </Form>
                  )}
                  </Formik>
              </FormContainer>
            }

            {typeLoading ? (
                <Loader />
            ) : typeError ? (
                <Message variant='danger'>{typeError}</Message>
            ) : (
                    <Row>
                      <h5>项目:</h5>
                      <Nav variant="pills" activeKey={`${typeId}`}>
                      { 
                          types && types.map((type) => (
                              <Nav.Item key={type.typeId}>
                                <Nav.Link eventKey={type.typeId} onClick={() => handleOneClick(type.typeId)}>{type.typeName}</Nav.Link>
                              </Nav.Item>
                      ))}
                      </Nav>
                    </Row>
            )}


            {loading ? (
                <Loader />
            ) : error ? (
                <Message variant='danger'>{error}</Message>
            ) : (
                    <Row>
                    { 
                        infos && infos.map((info) => (
                            <Col key={info.id} sm={12} md={6} lg={4} xl={3}>
                                <Info info={info} typeId={typeId||0} pageNum={pageNum||1} title={title||'null'}/>
                            </Col>
                    ))}

                        <Pagination size="lg">
                                <Pagination.First onClick={() => handlePage(1)} />
                                <Pagination.Prev onClick={() => handlePage(pageNum-1)}/>
                                {
                                pageList.map((page, index) => (
                                    <Pagination.Item key={index} active={page === pageNum} onClick={() => handlePage(page)}>{page}</Pagination.Item>
                                ))
                                }
                                <Pagination.Next onClick={() => handlePage(pageNum+1)}/>
                                <Pagination.Last onClick={() => handlePage(pageLast)}/>
                                
                                <Formik
                                  onSubmit={(values) => {
                                      submitHandler(values);
                                  }}
                                  initialValues={{
                                    pageNum: '',
                                  }}
                                >
                                  {({
                                    handleSubmit,
                                    handleChange,
                                    handleBlur,
                                    values,
                                    touched,
                                    isValid,
                                    errors,
                                    setFieldValue,
                                  }) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                  <Form.Group controlId='pageNum'>
                                    <Col sm={5}>
                                      <Form.Control
                                        type='pageNum'
                                        placeholder='1'
                                        value={values.pageNum}
                                        onChange={handleChange}
                                        isInvalid={!!errors.pageNum}
                                      ></Form.Control>
                                      </Col>
                                  </Form.Group>
                                </Form>
                                )}
                                </Formik>
                        </Pagination>

                      
                    </Row>
            )}
        </>
    )
}

export default HomeScreen
