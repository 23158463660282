import axios from "axios";
import Qs from "qs";

export default {

  /**
   * 查询类型
   * @param {*} info 
   * @returns 
   */
  getTypeList() {
    return axios({
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      method: "post",
      url: "/shop/type/getTypeList",
      data: null,
    }).then((res) => {
      //console.log("retValue=" + JSON.stringify(res));
      return res.data;
    });
  },
 
  
};
