/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { Row, Col, Image, ListGroup, Card, Button, ListGroupItem, Form, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
//import products from '../products'
import { Link } from 'react-router-dom'
import Rating from '../components/Rating'
import {
  listInfoDetails,
} from '../actions/InfoActions'
import Meta from '../components/Meta'
import Loader from '../components/Loader'
import Message from '../components/Message'
import QRCode  from 'qrcode.react';
import banner from '../static/image/banner.jpeg'

const InfoScreen = ({ history, match }) => {
  const [qty, setQty] = useState(1)
  const [classifyType, setClassifyType] = useState('oneMonth')
  const [rating, setRating] = useState(0)
  const [comment, setComment] = useState('')
  const [price, setPrice] = useState(1)
  const dispatch = useDispatch()
  //=> product.productKey === match.params.productKey )
  const infoDetails = useSelector((state) => state.infoDetails)
  const { loading, error, info } = infoDetails

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
   //弹出框的状态
   const [show, setShow] = useState(false)

  const pageNumber = match.params.pageNumber || 1
  const typeIdNum = match.params.typeIdNum || 1
  const titleContent = match.params.titleContent || 'null'

  useEffect(() => {
      let appUserId = null;
      let token = null;
      if (userInfo) {
        appUserId = userInfo.id;
        token = userInfo.token;
      }
      let infoParam = { 
        id: match.params.id,
        appUserId: appUserId,
        token: token,
      }

      dispatch(listInfoDetails(infoParam))
    // eslint-disable-next-line
  }, [dispatch, match])


  const handleDownload = (download) => {
    console.log("handleDownload download="+ download);
    if(download == null || download == "") {
      return ;
    }
    if(download.includes("http")) {
      window.open(download);
    } else {
      setShow(true);
    }
  };

    //创建开启和关闭弹出框的函数
    const handleClose = () => {
      setShow(false);
    }

  return (
    <>
      {/* <Row>
          <img src={banner} source={banner} alt="banner" style={{ width: "100%", height: 300, marginBottom: 20 }} onClick={() => imageClick()}/>
      </Row> */}
      
      <Link className='btn btn-dark my-3' to={`/home/${typeIdNum}/${pageNumber}/${titleContent}`}>
        返回主页
      </Link>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error}</Message>
      ) : (
        <>
          <Meta title={info.title} />
          <Row>
            <Col md={4}>
              <Image src={info.image} alt={info.title} fluid />
            </Col>

            <Col md={8}>
              <ListGroup variant='flush'>
                  <ListGroup.Item><h5>{info.title}</h5></ListGroup.Item>
                  <ListGroup.Item>时间：{info.publicTime}</ListGroup.Item>
                  <ListGroup.Item>
                      <Button
                        onClick={() => handleDownload(info.download)}
                        className='btn-block'
                        variant="danger"
                        type='button'
                        //disabled={product.stockNum === 0}
                      >
                        立即下载
                      </Button>
                      <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>信息提醒</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <p>{info.download}</p>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button variant='primary' onClick={handleClose}>
                            关闭
                          </Button>
                        </Modal.Footer>
                      </Modal>
                  </ListGroup.Item>
              </ListGroup>
            </Col>

          </Row>
          <Row>
            <Col md={12}>
              <ListGroup variant='flush'>
                  <ListGroup.Item><h5>详细介绍</h5></ListGroup.Item>
                  <ListGroup.Item><p style={{ whiteSpace: 'pre-wrap' }}>{info.introduce}</p></ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <Row>
            { 
                info.portraitList && info.portraitList.map((portrait,index) => (
                    <Col key={index} sm={12} md={6} lg={4} xl={3}>
                        <Image src={portrait} fluid />
                    </Col>
            ))}
          </Row>
          </>
      )}
    </>
  ) 
}

export default InfoScreen
