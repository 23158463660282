import React,{ useState, useEffect } from 'react';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../actions/UserActions'

const Header = () => {
    const dispatch = useDispatch()
    const userLogin = useSelector((state) => state.userLogin)
    const { userInfo } = userLogin

    //退出函数
    const logoutHandler = () => {
        dispatch(logout())
    }

  return (
    <header>
        <Navbar bg="dark" variant='dark' expand="lg" collapseOnSelect>
            <Container>
                <LinkContainer to='/'>
                    <Navbar.Brand>青年创业网</Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">

                    <LinkContainer to='/products/25868c6d131e79d7aa6cbf2603f0e74d'>
                        <Nav.Link><i className="fas fa-shopping-cart"></i> 升级VIP</Nav.Link>
                    </LinkContainer>

                    {userInfo ? (
                        <NavDropdown title={userInfo.name} id='username'>
                        <LinkContainer to='/profile'>
                            <NavDropdown.Item>个人详情</NavDropdown.Item>
                        </LinkContainer>
                        <LinkContainer to='/updatePassword'>
                            <NavDropdown.Item>修改密码</NavDropdown.Item>
                        </LinkContainer>
                        <NavDropdown.Item onClick={logoutHandler}>
                            退出
                        </NavDropdown.Item>
                        </NavDropdown>
                    ) : (
                        <>
                        <LinkContainer to='/login'>
                            <Nav.Link>
                                <i className='fas fa-user'></i>登录
                            </Nav.Link>
                        </LinkContainer>
                        <LinkContainer to='/register'>
                            <Nav.Link>
                                <i className='fas fa-user-plus'></i>注册
                            </Nav.Link>
                        </LinkContainer>
                        </>
                    )}
                </Nav>
                </Navbar.Collapse>
            </Container> 
        </Navbar>
    </header>
  )
}

export default Header
